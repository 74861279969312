@charset "UTF-8";

@use "_colors";
@use "_text";

/* Slider */
.slick-slider {
  position: relative;

  display: block;

  -webkit-box-sizing: border-box;
          box-sizing: border-box;

  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  -webkit-touch-callout: none;
   -khtml-user-select: none;
  -ms-touch-action: pan-y;
      touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  width: 100%;
}

.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/*********************************

slick-theme.css

*********************************/
/* Slider */
.slick-loading .slick-list {
  background: colors.$light-background;
}

/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;

  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  z-index: 999;
  top: 34%;

  display: block;

  width: 24px;
  height: 46px;
  margin-top: -16px;
  padding: 0;

  cursor: pointer;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
          transform: translate(0, -50%);

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.section_works .slick-prev,
.section_works .slick-next {
  top: 34%;
}


.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  /*background: transparent;*/
  opacity: .8;
  color: transparent;
  outline: none;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: .75;
  color: colors.$light-text;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -50px;
  background: url(../images/cmn/prev.png);
  background-size: 100% auto;
}

[dir="rtl"] .slick-prev {
  right: -50px;
  left: auto;
}

.slick-next {
  right: -50px;
  background: url(../images/cmn/next.png);
  background-size: 100% auto;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -20px;
}

/* Dots */
.slick-slider {
  margin-bottom: 0;
}

.top_section06.slick-initialized.slick-slider {
  margin-bottom: 0;
}

.slick-dots {
  position: absolute;
  bottom: -38px;

  display: block;

  width: 100%;
  margin: 0 !important;
  padding: 0;

  list-style: none;

  text-align: center;
}

.slick-dots li {
  position: relative;

  display: inline-block;

  width: 14px;
  height: 14px;
  margin: 0 4px;
  padding: 0;
  top: 34%;
  cursor: pointer;

  border-radius: 10px;
  background: #BFC0C6;
}

.slick-dots li.slick-active {
  background: colors.$black;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  text-align: center;

  opacity: .25;
  color: colors.$dark-text;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: colors.$dark-text;
}

@media screen and (max-width: 740px) {
  .slick-prev,
  .slick-next {
    width: 14px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .slick-prev {
    left: -8%;
  }
  .slick-next {
    right: -8%;
  }
/*  .slick-slider {
    padding-bottom: 10%;
  }*/
  .slick-dots {
    bottom: 0px;
  }
  .slick-dots li {
    width: 10px;
    height: 10px;
  }
}
